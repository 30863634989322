body {
 padding: 5%;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input[type=text], input[type=email], textarea{
  border: 2px solid #000;
  border-radius: 3px;
  padding: 10px;
  width: 300px;
  margin-bottom: 10px;
}
button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  font-weight: bold;
  border-radius: 3px;
}